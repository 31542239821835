import request from '@/utils/axios'

//登录
export function login(params) {
  return request({
    url: '/miqu/miniprogram/api/v1/account/admin/login',
    method: 'post',
    data:params,
    type: 'json'
  })
}

// 导出优惠券
export function exportCoupon(params) {
  return request({
    url: '/miqu/api/v1/coupon/export',
    method: 'post',
    data:params,
    responseType: 'blob',
    type: 'download2'
  })
}

// 玩家发布-分页查询
export function getMomentPage(params) {
  return request({
    url: '/miqu/web/v1/moment/page',
    method: 'get',
    data: params
  })
}

// 玩家发布-违规
export function momentIllegal(params) {
  return request({
    url: '/miqu/web/v1/moment/illegal',
    method: 'post',
    data: params,
    type: 'json'
  })
}

// 玩家发布-详情
export function getMomentInfo(params) {
  return request({
    url: `/miqu/web/v1/moment/info/${params.id}`,
    method: 'get'
  })
}

// 敏感词管理
export function getSensitiveWord(params) {
  return request({
    url: '/sensitive-word',
    method: 'get',
    data: params
  })
}

// 敏感词管理-新增
export function setSensitiveWord(params) {
  return request({
    url: '/sensitive-word',
    method: 'post',
    data: params,
    type: 'json'
  })
}
