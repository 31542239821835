import Vue from 'vue'
import Router from 'vue-router'
// import {Layout, Content} from "../layout"; // 页面整体布局
import Content from "../layout/content";
import Layout from "../layout/home";// 页面整体布局
import {topRouterMap} from "./topRouter";
import store from "@/store";

Vue.use(Router);
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

function filterTopRouterMap(name) {
  let router = topRouterMap.find((item) => {
    return item.parentName === name;
  });
  return router.data; // arr
}

/**
 * 1、roles:后台返回的权限结构;
 *
 */
//手动跳转的页面白名单
const whiteList = [
  '/',
  '/login'
];
/**
 * path:''与path:'*'的区别：
 * 1、path:'*', 会匹配所有路径
 * 2、path:''，也是会匹配到路由
 *
 */

//异步路由（需要权限的页面）
export const asyncRouterMap = [
  {
    path: '/home',
    name: 'home',
    component: Layout,
    meta: {
      title: '首页',
      icon: 'iconuser',
    },
    noDropdown: true,
    children: [
      {
        path: '/home/slideShow',
        meta: {
          title: '轮播图',
          icon: 'icon-charutupian',
          routerType: 'leftmenu',
          titleList: [
            {"path": "slideShowIndex", "title": "列表"},
            {"path": "slideShowAdd", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('slideShow')
      },
      {
        path: '/home/mainButton',
        meta: {
          title: '主按钮',
          icon: 'icon-suoyoudingdan',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/home/mainButton/index')
      },
      {
        path: '/home/weflareUpload',
        meta: {
          title: '福利上传',
          icon: 'icon-iconfontzhizuobiaozhun023140',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/home/weflareUpload/index')
      },
      {
        path: '/home/ui',
        meta: {
          title: 'UI框',
          icon: 'icon-huifumorenbuju',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/home/ui/index')
      },
      {
        path: '/home/hotStory',
        meta: {
          title: '热门推荐',
          icon: 'icon-tuijian',
          routerType: 'leftmenu',
          titleList: [
            {"path": "hotStoryIndex", "title": "列表"},
            {"path": "hotStoryAdd", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('hotStory')
      },
      {
        path: '/home/tutorial',
        meta: {
          title: '新手指南',
          icon: 'icon-xinshouzhinan',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/home/tutorial/index')
      },
      {
        path: '/home/cooperation',
        meta: {
          title: '商务合作',
          icon: 'icon-shangwuhezuo',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/home/cooperation/index')
      },
      {
        path: '/home/pushMessage',
        meta: {
          title: '消息推送',
          icon: 'icon-xiaoxi',
          routerType: 'leftmenu',
          titleList: [
            {"path": "pushMessageIndex", "title": "列表"},
            {"path": "pushMessageAdd", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('pushMessage')
      },
      {
        path: '/home/qrCode',
        meta: {
          title: '二维码',
          icon: 'icon-erweima',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/home/qrCode/index')
      },
      {
        path: '/home/bootAdvertising',
        meta: {
          title: '开机广告',
          icon: 'icon-guanggaoguanli',
          routerType: 'leftmenu',
          titleList: [
            {"path": "slideShowIndex", "title": "列表"},
            {"path": "slideShowAdd", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('bootAdvertising')
      },
      {
        path: '/home/rankingList',
        meta: {
          title: '排行榜',
          icon: 'icon-paihangbang',
          routerType: 'leftmenu',
          titleList: [
            {"path": "rankingList", "title": "列表"},
            {"path": "rankingAdd", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('rankingList')
      },
      {
        path: '/home/questionList',
        meta: {
          title: '常见问题',
          icon: 'icon-paihangbang',
          routerType: 'leftmenu',
          titleList: [
            {"path": "questionList", "title": "列表"},
            {"path": "questionAdd", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('questionList')
      },
      {
        path: '/home/discoveryContentManagement',
        meta: {
          title: '发现内容管理',
          icon: 'icon-tuijian',
          routerType: 'leftmenu',
          titleList: [
            {"path": "list", "title": "列表"},
            {"path": "addDiscoveryContent", "title": "添加发现内容"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('discoveryContentManagement')
      }
    ]
  },
  {
    path: '/productList',
    name: 'productList',
    component: Layout,
    meta: {
      title: '产品列表',
      icon: 'iconshare',
    },
    noDropdown: false,
    children: [
      {
        path: '/productList/sale',
        meta: {
          title: '在售产品',
          icon: 'icon-chanpin',
          routerType: 'leftmenu',
          titleList: [
            {"path": "sale", "title": "列表"},
            {"path": "release", "title": "发布产品"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('sale')
        // component: () => import('@/page/productList/sale/index'),
      },
      {
        path: '/productList/order',
        meta: {
          title: '订单管理',
          icon: 'icon-dingdan',
          routerType: 'iframemenu'
        },
        component: Content,
        children: [
          {
            path: '/productList/order/allOrder',
            meta: {
              title: '全部订单',
              icon: 'icon-henggang',
              routerType: 'leftmenu',
              titleList: [
                {"path": "allOrder", "title": "列表"},
                {"path": "allOrderDetial", "title": "订单详情"}
              ]
            },
            component: Content,
            children: filterTopRouterMap('allOrder')
            // component: () => import('@/page/productList/order/allOrder/index'),
          },
          {
            path: '/productList/order/afterSale',
            meta: {
              title: '售后订单',
              icon: 'icon-henggang',
              routerType: 'leftmenu'
            },
            component: () => import('@/page/productList/order/afterSale/index'),
          },
          {
            path: '/productList/order/coinCertificate',
            meta: {
              title: '兑换券',
              icon: 'icon-henggang',
              routerType: 'leftmenu'
            },
            component: () => import('@/page/productList/order/coinCertificate/index'),
          }
        ]
      }
    ]
  },
  {
    path: '/story',
    name: 'story',
    meta: {
      title: '探索/游戏',
      icon: 'iconinfo',
    },
    component: Layout,
    children: [
      {
        path: '/story/index',
        name: 'index',
        meta: {
          title: '故事大厅',
          icon: 'icon-tushuguanlibrary12',
          routerType: 'leftmenu',
          titleList: [
            {"path": "index", "title": "列表"},
            {"path": "roles", "title": "角色"},
            {"path": "addRoles", "title": "添加角色"},
            {"path": "clue", "title": "线索"},
            {"path": "addSlidebar", "title": "添加边栏"},
            {"path": "addClue", "title": "添加新线索"},
            {"path": "survey", "title": "问卷"},
            {"path": "addSurveyQuestion", "title": "添加问题"},
            {"path": "votes", "title": "投票"},
            {"path": "addVotesQuestion", "title": "添加问题"},
            {"path": "npc", "title": "NPC"},
            {"path": "addNPC", "title": "设置NPC"},
            {"path": "addSawPuzzleClue", "title": "拼图线索"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('story')
      },
      {
        path: '/story/npcManage/npcManage',
        name: 'index',
        meta: {
          title: 'NPC管理',
          icon: 'icon-changyonglogo37',
          routerType: 'leftmenu',
          titleList: [
            {"path": "npcManage", "title": "列表"},
            {"path": "addNPC", "title": "添加NPC"},
          ]
        },
        component: Content,
        children: filterTopRouterMap('npcManage')
      },
      {
        path: '/story/questionBank',
        name: 'questionBank',
        meta: {
          title: '线索题库',
          icon: 'icon-tushuguanlibrary12',
          routerType: 'leftmenu',
          titleList: [
            {"path": "index", "title": "列表"},
            {"path": "gapFillingQuestionBank", "title": "填空题题库"},
            {"path": "choiceQuestionBank", "title": "选择题题库"},
            {"path": "questionBankInfo", "title": "题库列表"},
            {"path": "editQuestion", "title": "新增/编辑"},
            // {"path": "addRoles", "title": "添加角色"},
            // {"path": "clue", "title": "线索"},
            // {"path": "addSlidebar", "title": "添加边栏"},
            // {"path": "addClue", "title": "添加新线索"},
            // {"path": "survey", "title": "问卷"},
            // {"path": "addSurveyQuestion", "title": "添加问题"},
            // {"path": "votes", "title": "投票"},
            // {"path": "addVotesQuestion", "title": "添加问题"},
            // {"path": "npc", "title": "NPC"},
            // {"path": "addNPC", "title": "设置NPC"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('questionBank')
      },
    ]
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '用户',
      icon: 'icon-yonghu-yuan',
    },
    component: Layout,
    children: [
      {
        path: '/user/play',
        name: 'play',
        meta: {
          icon: 'icon-yonghu-yuan',
          title: '游戏用户',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/user/play/index'),
      },
      {
        path: '/user/qm',
        name: 'qm',
        meta: {
          title: '趣盟伙伴',
          icon: 'icon-changyongtubiao-fuben-29',
          routerType: 'leftmenu',
          titleList: [
            {"path": "qmIndex", "title": "列表"},
            {"path": "addQM", "title": "添加趣盟伙伴"},
            {"path": "discount", "title": "商家优惠券列表"},
            {"path": "edit", "title": "编辑"},
            {"path": "viewCommission", "title": "查看佣金"},
            {"path": "correlationProduct", "title": "关联产品"},
          ]
        },
        component: Content,
        children: filterTopRouterMap('qm')
      },
      {
        path: '/user/footmarkBackground',
        name: 'footmarkBackground',
        meta: {
          title: '足迹背景',
          icon: 'icon-charutupian',
          routerType: 'leftmenu',
          titleList: [
            {"path": "footmarkList", "title": "列表"},
            {"path": "addFootmark", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('footmarkBackground')
      },
      {
        path: '/user/footmarkPoint',
        name: 'footmarkPoint',
        meta: {
          title: '足迹地图',
          icon: 'icon-dianzishapan',
          routerType: 'leftmenu',
          titleList: [
            {"path": "pointList", "title": "列表"},
            {"path": "addFootmarkPoint", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('footmarkPoint')
      },
      {
        path: '/user/extractRecord',
        name: 'play',
        meta: {
          icon: 'icon-yonghu-yuan',
          title: '提现记录',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/user/extractRecord/index'),
      }
    ]
  },
  {
    path: '/member',
    name: 'member',
    meta: {
      title: '会员',
      icon: 'icon-yonghu-yuan',
    },
    component: Layout,
    children: [
      {
        path: '/member/userMember',
        name: 'userMember',
        meta: {
          title: '用户会员',
          icon: 'icon-charutupian',
          routerType: 'leftmenu',
          titleList: [
            {"path": "userMemberList", "title": "列表"},
            {"path": "addUserMember", "title": "添加"}
          ]
        },
        component: Content,
        children: filterTopRouterMap('userMember')
      }
    ]
  },
  {
    path: '/points',
    name: 'points',
    meta: {
      title: '积分',
      icon: 'icon-yonghu-yuan',
    },
    component: Layout,
    children: [
      {
        path: '/points/pointList',
        name: 'pointList',
        meta: {
          title: '会员积分列表',
          icon: 'icon-charutupian',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/points/pointList/index'),
      },
      {
        path: '/points/pointsSpendingList',
        name: 'pointsSpendingList',
        meta: {
          title: '会员积分消费列表',
          icon: 'icon-charutupian',
          routerType: 'leftmenu'
        },
        component: () => import('@/page/points/pointsSpendingList/index'),
      },
    ]
  },
  {
    path: '/exchangePlatform',
    name: 'exchangePlatform',
    meta: {
      title: '交换平台',
      icon: 'icon-yonghu-yuan',
    },
    component: Layout,
    children: [
      {
        path: '/exchangePlatform/quAuction',
        meta: {
          title: '趣拍拍',
          icon: 'icon-dingdan',
          routerType: 'iframemenu'
        },
        component: Content,
        children: [
          {
            path: '/exchangePlatform/quAuction/beat',
            meta: {
              title: '拍得数据',
              icon: 'icon-henggang',
              routerType: 'leftmenu',
            },
            component: () => import('@/page/exchangePlatform/quAuction/beat/index'),
          },
          {
            path: '/exchangePlatform/quAuction/knockOut',
            meta: {
              title: '拍出数据',
              icon: 'icon-henggang',
              routerType: 'leftmenu'
            },
            component: () => import('@/page/exchangePlatform/quAuction/knockOut/index'),
          },
        ]
      }
    ]
  }
];
//默认不需要权限的页面
export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    redirect: '/login',
    hidden: true
  },
  {path: '/login', name: 'login', component: () => import('@/page/login'), hidden: true},
  {path: '/404', component: () => import('@/page/errorPage/404'), hidden: true},
  {path: '/401', component: () => import('@/page/errorPage/401'), hidden: true}
].concat(asyncRouterMap)

//注册路由
export default new Router({
  mode: 'hash', // 默认为'hash'模式
  base: process.env.BASE_URL, // 添加跟目录,对应服务器部署子目录
  routes: constantRouterMap
})
store.commit('SET_ROUTERS', asyncRouterMap)

/**
 *  路由设置要求：
 * 1、该路由有子菜单,可以设置多层嵌套路由children;如果没有子菜单,不需要设置children;通过item.children.length来判断路由的级数;
 * 2、登录成功后,定位到系统首页时,需要加载页面整体布局组件Layout并进行子路由定向加载;
 *
 * 按需加载路由组件的2种方法：
 * 1、component: () => import('@/page/login')
 * 2、component:resolve => require(['@/page/fundPosition'], resolve)
 *
 *
 *
 * 什么情况下，路由会定位到404页面?
 * 路由中redirect:'',不起作用？
 * 三级子菜单要在顶部展示？
 *
 *
 *
 */

